<script>
  export default {
    components: {
      NewsCard: () => import("./NewsCard.vue"),
    },
    data() {
      return {
        error: null,
        currentPage: 2,
        totalPages: null,
        isLoading: false,
        limit: 10,
      };
    },
    computed: {
      news() {
        return this.$store.state.news.others.news;
      },
      screenWidth() {
        return this.$screen.width;
      },
    },
    methods: {
      async loadNews() {
        if (this.isLoading || (this.totalPages && this.currentPage > this.totalPages)) return;
        try {
          this.isLoading = true;
          const { data } = await this.$api.get(`news${location.search || "?"}&others=true&page=${this.currentPage}`);
          this.newsLoaded(data);
          this.isLoading = false;
          this.currentPage++;
        } catch (error) {
          this.$Swal.fire({ title: "Error", text: error });
          this.newsClose();
        }
      },
      newsLoaded(data) {
        if (!data.news || !data.news.length) {
          this.error = data.message || "No se encontraron noticias";
        } else {
          this.$store.state.news.others.news = [...this.news, ...data.news];
          this.totalPages = Math.ceil(data.total / this.limit);
        }
      },
      handleScroll() {
        const observer = this.$refs.scrollObserver;
        if (observer) {
          const observerPos = observer.getBoundingClientRect().top;
          const windowHeight = this.$screen.height;

          if (observerPos - windowHeight <= 800 && !this.isLoading) {
            this.loadNews();
          }
        }
      },
      clearNewsState() {
        this.$store.state.news.others.news = [];
        this.currentPage = 2;
        this.totalPages = null;
        this.error = null;
      },
    },
    mounted() {
      window.addEventListener("scroll", this.handleScroll);
      window.addEventListener("resize", this.handleResize);
      this.loadNews();
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.handleScroll);
      window.removeEventListener("resize", this.handleResize);
      this.clearNewsState();
    },
  };
</script>

<template>
  <div class="others-news" v-if="news && news.length">
    <div class="others-news-title">
      <div class="others-news-title-left" dcolor>OTRAS NOTICIAS</div>
    </div>
    <div class="main-news" v-if="news">
      <NewsCard v-for="(mynews, index) in news" :key="index + mynews._id" :news="mynews" block="others" />
    </div>
    <div ref="scrollObserver" class="scroll-observer"></div>
  </div>
</template>

<style lang="scss">
  .others-news {
    position: relative;
    padding: $mpadding $space-0 $mpadding * 2;
    margin: $mpadding 0 0 0;
    &-title {
      font-family: $sec_font;
      margin: 0 0 $mpadding 0;
      &-left {
        font-size: 150%;
      }
      > * {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .view-all-btn {
        cursor: pointer;
      }
    }
    &-slide {
      .slick-track {
        display: flex;
      }
      .slick-slide {
        @include Margin(0, $mpadding, 0, $mpadding);
      }
      .slick-list {
        @include Margin(0, -$mpadding, 0, -$mpadding);
      }
    }
    &-arrow {
      width: $mpadding * 3;
      height: $mpadding * 3;
      font-size: 200%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: inherit;
      background: rgba(255, 255, 255, 0.8);
      -webkit-backdrop-filter: blur(4px);
      backdrop-filter: blur(4px);
      z-index: 1;
      border-radius: 50%;
      &::before,
      &::after {
        display: none;
      }
      &:hover {
        color: $primary-color;
        background: rgba(255, 255, 255, 0.8);
      }
      &.__left {
        left: $mpadding;
      }
      &.__right {
        right: $mpadding;
      }
      &:active {
        opacity: 0.5;
      }
    }
    .news-card-title {
      .text {
        line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &-items {
      display: flex;
      flex-flow: wrap;
    }

    .main-news {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: $mpadding;
    }

    .news-card {
      max-width: 100%;
      width: 100%;
    }
    @media (max-width: 434px) {
      margin-bottom: $space-40;
    }

    @media (max-width: 434px) {
      margin-bottom: $space-40;
    }

    @media (min-width: 435px) {
      margin-bottom: $space-40;
      .news-card {
        max-width: 100%;
        width: 100%;
      }
    }

    @media (min-width: 600px) {
      .main-news {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    @media (min-width: 1024px) {
      margin-bottom: $space-0;
      .main-news {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    @media (min-width: 1800px) {
      .main-news {
        grid-template-columns: repeat(4, 1fr);
      }
    }

    @media (min-width: 1690px) {
      &-body {
        .main-news {
          padding: 0 0;
        }
      }
    }
  }
</style>
